@charset "UTF-8";
*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;500&display=swap');

/* ==================================================================
 *  reset
 * ================================================================== */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
line-height: 1;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}

/* ==================================================================
 *  Base
 * ================================================================== */

$SP-width: 768px;
$PC-width: 1200px;

@mixin SP {
  @media (max-width: 430px) {
    @content;
  }
}
@mixin TB {
  @media (max-width: 1020px) and (min-width: $SP-width) {
    @content;
  }
}

@mixin PC {
  @media (min-width: 960px) {
    @content;
  }
}

@function rem($size) {
  @if (unit($size) != px) {
    @error "$size must be pixels.";
  }
  @return ($size / 16px) * 1rem;
}

html,body {
  font-family: 'Rubik', "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  color: #3E2F2F;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust:100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #fff;
  font-size: 16px;
  font-weight: 300;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

main{
  width: 100%;
  flex-grow: 1;
  padding-bottom: 35px;
}

a{
  color: #3E2F2F;
  transition: opacity 0.3s linear;
  text-decoration: none;
  &:hover{
    opacity: 0.8;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

h2 {
  margin: 50px auto;
  font-size: 12px;
  text-align: center;
  color: #3E2F2F;
  span {
    display: block;
    margin-top: 12px;
  }
}

.join_btn,input[type="submit"] {
  background: #663333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
  line-height: 3.5rem;
  font-weight: bold;
  border-radius: 5px;
  font-size: 18px;
  position: relative;
  border: none;
  &:before {
    content: "";
    width: 1.2em;
    height: 1.2em;
    margin: auto auto auto 1.5em;
  }
  &:after {
    content: "";
    background: url("./image/arrow.svg") no-repeat;
    background-size: 100%;
    width: 2em;
    height: 0.5em;
    margin: auto 1.5em auto auto;
  }
}

.note {
  font-size: 80%;
  text-align-last: left;
  text-indent: -1em;
  padding-left: 0.5em;
  line-height: 1.3;
  display: inline-block;
}

/* ==================================================================
 *  header
 * ================================================================== */

header {
  position: fixed;
  z-index: 1000;
  padding: 10px 15px;
  width: 100%;
  background: #fff;
  h1 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: rem(12px);
  }
}

/* ==================================================================
 *  top
 * ================================================================== */

.top {
  z-index: 1000;
  position: relative;
}

/* ==================================================================
 *  about
 * ================================================================== */

 .about {
   text-align: center;
   width: 100%;
   max-width: 800px;
   font-size: 14px;
   margin: 80px auto 100px;
   p {
     line-height: 2.1;
     margin-bottom: 1.5em;
     @include SP {
       font-size: rem(13px);
      white-space: pre-line;
      line-height: 1.8;
      br {
        display: none;
      }
     }
     strong {
       font-weight: bold;
     }
   }
   .join_btn {
    position: fixed;
    bottom: 15px;
    right: 20px;
    border-radius: 50%;
    flex-direction: column;
    line-height: 1.2;
    width: 100px;
    height: 100px;
    font-size: rem(15px);
    z-index: 998;
    &:after {
      margin: 10px auto 15px;
    }
   }
 }


/* ==================================================================
 *  profile
 * ================================================================== */

.profile {
  &_card {
    background: #FAF9FB;
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto 100px;
    padding: 34px;
    @include SP {
      flex-direction: column;
      background: #fff;
      padding: 0;
      margin-bottom: 50px;
    }
  }
  &_image {
    margin-right: 30px;
    width: 220px;
    @include SP {
      width: 100%;
      margin: 10px auto 15px;
    }
  }
  &_text {
    align-self: center;
    width: calc(100% - 220px);
    @include SP {
      width: 100%;
      padding: 20px 30px;
    }
    .name {
      font-size: rem(22px);
      font-weight: bold;
      margin-bottom: 10px;
    }
    p {
      font-size: rem(14px);
      line-height: 1.8;
      text-align: justify;
    }
    .link_list {
      display: inline-flex;
      margin-top: 15px;
      @include SP {
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
      }
      li {
        margin-right: 30px;
        @include SP {
          margin-right: 0;
          width: 30%;
        }
        img {
          height: 18px;
          @include SP {
            height: 16px;
          }
        }
      }
    }
  }
}

/* ==================================================================
 *  price
 * ================================================================== */

.price {
  background: #FAF9FB;
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin: 40px auto 20px;
  }
  .price_text {
    margin-bottom: 30px;
  }
  .campaign {
    max-width: 400px;
    margin: 0 auto 40px;
    padding: 0 10px;
    p {
      font-size: rem(12px);
      line-height: 1.4;
      margin-top: 15px;
    }
  }
  >a {
    max-width: 400px;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    font-size: rem(14px);
    margin-bottom: 20px;
    &:after {
      content: "";
      background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212.222%22%20height%3D%226.611%22%20viewBox%3D%220%200%2012.222%206.611%22%3E%0A%20%20%3Cpath%20id%3D%22chevron_down%22%20d%3D%22M11.308.5%2C5.9%2C5.9.5.5%22%20transform%3D%22translate(0.207%200.207)%22%20fill%3D%22none%22%20stroke%3D%22%233e2f2f%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221%22%2F%3E%0A%3C%2Fsvg%3E%0A') no-repeat right center;
      width: 14px;
      height: 7px;
      display: inline-flex;
      margin-left: 10px;
    }
    @include SP {
      border-top: 1px solid #C7C5C5;
      margin-bottom: 0;
    }
  }
}

 /* ==================================================================
 *  contents
 * ================================================================== */

.contents {
  width: calc(100% - 50px);
  max-width: 1000px;
  margin: 60px auto 100px;
  &_list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    li {
      display: flex;
      width: 100%;
      margin: 0 auto 60px;
      flex-direction: column;
      @media (min-width: 800px) {
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 100px;
      }
      &:nth-child(odd){
        .contents_image {
          flex-direction: row-reverse;
          margin-right: auto;
          @media (min-width: 800px) {
            flex-direction: row;
          }
        }
        .contents_detail {
          @include SP {
            margin-right: auto;
          }
        }
      }
      &:nth-child(even){
        .contents_image {
          margin-left: auto;
          @media (min-width: 800px) {
            order: 2;
            flex-direction: row-reverse;
          }
        }
        .contents_detail {
          margin-left: auto;
          @media (min-width: 800px) {
            margin-left: 0;
            h3,p {
              margin-left: auto;
            }
          }
        }
      }
      .contents_image {
        display: flex;
        gap: 10px;
        width: 95%;
        @media (min-width: 800px) {
          width: 380px;
        }
        span {
          font-family: serif;
          writing-mode: vertical-rl;
          font-size: rem(14px);
          letter-spacing: 0.04em;
        }
      }
      .contents_detail {
        width: 90%;
        padding-left: 10px;
        margin-bottom: 50px;
        @media (min-width: 800px) {
          width: calc(100% - 480px);
          padding-left: 0;
        }
        h3 {
          font-weight: bold;
          max-width: 400px;
          margin-block: 1.5em;
          font-family: serif;
        }
        p {
          line-height: 1.8;
          font-size: rem(14px);
          max-width: 400px;
        }
      }
    }
  }
  &_text {
    text-align: center;
    margin: 0 auto 120px;
    p {
      line-height: 1.6;
      font-size: rem(14px);
      margin-bottom: 1.5em;
    }
    img {
      display: block;
      margin: 0 auto 20px;
    }
  }
}

/* ==================================================================
 *  howto
 * ================================================================== */

#howto {
  background: #FAF9FB;
  padding-bottom: 50px;
  overflow: hidden;
}

.howto {
  width: 100%;
  margin: 50px auto 70px;
  text-align: center;
  scroll-margin-top: 80px;
  &_list {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 50px;
    width: calc(100% - 50px);
    max-width: 1000px;
    @include SP {
      flex-direction: column;
      margin-bottom: 0;
    }
    li {
      width: 30%;
      font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 60px rgba(76,86,100, 0.1);
      position: relative;
      @include SP {
        width: 90%;
        margin: 0 auto 60px;
        &:not(.on):last-child {
          margin-bottom: 30px;
        }
      }
      &:before {
        content: "";
        width: 60px;
        height: 60px;
        position: absolute;
        top: -15px;
        left: -15px;
      }
      &:nth-child(1):before {
        background: url("./image/step_01.svg") no-repeat;
        background-size: contain;
      }
      &:nth-child(2):before {
        background: url("./image/step_02.svg") no-repeat;
        background-size: contain;
      }
      &:nth-child(3):before {
        background: url("./image/step_03.svg") no-repeat;
        background-size: contain;
      }
      &:not(:last-child):after,
      &.on:after {
        content: "";
        position: absolute;
        right: -12%;
        top: 50%;
        margin-top: -25px;
        border-left: 16px solid #a19f9f;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        @include SP {
          top: calc(100% + 18px);
          right: 50%;
          margin: 0 -5px 0 0;
          transform: rotate(90deg);
        }
      }
      &:last-child.on:after {
        @media (min-width: 768px) {
          display: none;
        }
      }
      h3 {
        height: 2.6em;
        font-weight: bold;
        margin: 1em 0;
        font-size: rem(15px);
        line-height: 1.3;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
  .join_btn {
    width: calc(100% - 50px);
    max-width: 370px;
    margin: 30px auto;
  }
}

/* ==================================================================
 *  Participate
 * ================================================================== */

 .participate,
 .unsubscribe {
  padding: 20px 0 50px;
  background: #FAF9FB;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    background: #FAF9FB;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    z-index: 9999;
    mix-blend-mode: darken;
    pointer-events: none;
  }
  h2 {
    margin-bottom: 30px;
  }

  .read {
    line-height: 1.6;
    margin-bottom: 40px;
    font-size: rem(15px);
    padding: 0 2em;
    @include SP {
      font-size: rem(12px);
    }
  }

  .howto_list li:not(.on){
    opacity: 0.3;
    box-shadow: none;
    @include SP {
      display: none;
    }
  }

  .participate_form {
    margin: 0 auto;
    width: calc(100% - 50px);
    max-width: 1000px;
    ul {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: baseline;
      margin: 0 auto;
      padding: 20px;
      @include SP {
        padding: 0;
      }
      li {
        label {
          display: table;
          font-weight: bold;
          font-size: 80%;
          margin-bottom: 10px;
          @include SP {
            font-size: rem(12px);
          }
        }
        input:not([type="submit"]) {
          border: none;
          background: #fff;
          color: currentColor;
          border-radius: 4px;
          line-height: 50px;
          padding: 0 16px;
          outline: 0;
          min-width: 60px;
          width: 100%;
          box-shadow: 0 0 0 1px #d6d4d4;
          &::-webkit-autofill {
            box-shadow: 0 0 0 1000px #fff inset;
          }
          &:focus{
            box-shadow: 0 0 0 1px #d6d4d4;
          }
          &:invalid {
            box-shadow: 0 0 0 2px #f72b4f;
          }
          @include SP {
            font-size: 16px;
          }
        }
        input[type="submit"] {
          line-height: 50px;
          border-radius: 5px;
          font-size: 1em;
          width: 120px;
          display: block;
          justify-content: center;
          @include SP {
            width: 100%;
          }
        }
        &.email {
          width: 100%;
          max-width: 350px;
          @include SP {
            width: 100%;
            margin-bottom: 20px;
          }
        }
        &.submit {
          width: auto;
          margin-left: 15px;
          @include SP {
            width: 100%;
            margin: auto;
          }
        }
        &.instagram {
          width: 50%;
          padding-right: 5%;
          @include SP {
            width: 100%;
            padding: 0 0 10% 0;
          }
          >a {
            background: url("./image/icon_help.svg") no-repeat left center;
            background-size: 1.2em;
            padding: 5px 0 5px 24px;
            border-bottom: 1px solid;
            font-size: 90%;
            margin-top: 10px;
            display: inline-block;
          }
        }
        &.card {
          width: 50%;
          padding-left: 5%;
          border-left: 1px solid #CCCCCD;
          display: flex;
          flex-wrap: wrap;
          @include SP {
            width: 100%;
            padding: 10% 0 0 0;
            border-left: none;
            border-top: 1px solid #CCCCCD;
          }
          .card_num {
            width: 100%;
            margin-bottom: 20px;
            input {
              width: 100%;
            }
          }
          .card_limit {
            width: 50%;
            margin-right: 20px;
            div {
              display: flex;
              align-items: center;
              input {
                width: 100%;
              }
              i {
                font-size: 120%;
                padding: 0 7px;
              }
            }
          }
          .card_cvc {
            width: calc(50% - 20px);
            @include SP {
              input {
                width: 100%;
              }
            }
          }
          &+.submit {
            margin-top: 40px;
            width: 50%;
            max-width: 370px;
            @include SP {
              width: 100%;
            }
            input {
              width: 100%;
            }
          }
          .card_brand {
            margin: 20px 0 30px;
            p {
              font-size: 80%;
              font-weight: bold;
              @include SP {
                font-size: rem(12px);
              }
            }
            ul {
              justify-content: space-between;
              padding: 0;
              li{
                margin-right: 10px;
                margin-top: 10px;
                &:last-child {
                  margin-right: auto;
                }
              }
            }
          }
        }
      }
    }

    .error {
      color: #d41b67;
      font-weight: bold;
      margin-bottom: 20px;
      line-height: 1.6;
      font-size: 90%;
      & + ul li.card label {
        color: #d41b67;
      }
      & + ul li.card input {
        box-shadow: 0 0 0 2px #d41b67;
      }
    }

    .join_btn {
      max-width: 370px;
      margin: 0 auto 20px;
    }
  }
}

#help {
  position: fixed;
  background-color: rgba(100, 100, 100, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  &:target {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
    pointer-events: auto;
  }
  .help_cont {
    width: calc(100% - 50px);
    max-width: 500px;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: 0 2px 60px rgba(0,0,0, 0.1);
    display: flex;
    flex-wrap: wrap;
  }
  .help_image {
    border-top: 1px solid #eee;
    background:#FAF9FB;
    padding: 3em 2em 0;
    width: 100%;
    text-align: center;
    img {
      width: 100%;
      max-width: 320px;
    }
  }
  .help_close {
    width: 20px;
    height: 20px;
    margin: 1em 20px;
  }
  h3 {
    margin: 1em auto 1em 20px;
    line-height: 20px;
  }
  p {
    text-align: center;
    padding: 2.5em 1.5em;
    line-height: 1.6;
    font-size: 85%;
    margin: auto;
    span {
      border: 1px solid #707070;
      border-radius: 4px;
      padding: 2px 4px;
      color: #333;
      font-feature-settings: "palt";
      font-size: 95%;
      margin: 5px 4px;
      display: inline-block;
    }
    @include SP {
      text-align: left;
      br {
        display: none;
      }
    }
  }
}

/* ==================================================================
 *  Unsubscribe
 * ================================================================== */

.unsubscribe {
  padding: 50px 0 0;
  min-height: 300px;
  overflow: hidden;
  &:before {
    content: "";
    position: fixed;
    background: #FAF9FB;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  a,input[type="submit"] {
    background: #3E2F2F;
    line-height: 50px;
    border-radius: 5px;
    font-size: 1em;
    width: 120px;
    display: block;
    justify-content: center;
    text-align: center;
    color: #fff;
  }
  .participate_form >a {
    width: 100%;
    max-width: 300px;
    margin: 50px auto 100px;
  }
}

/* ==================================================================
 *  Q&A
 * ================================================================== */

.question {
  width: 85%;
  max-width: 1000px;
  margin: 0 auto;
  &_list {
    li {
      background: #FAF9FB;
      padding: 10px 20px;
      border-radius: 4px;
      margin-bottom: 20px;
      line-height: 1.4;
      @include SP {
        padding: 10px 15px;
      }
      .question_toggle {
        display: none;
        &:checked + .question_label:after {
          transform: rotate(180deg);
        }
        &:not(:checked) + .question_label + .answer {
          display: none;
        }
      }
      .question_label {
        background: url("./image/question.svg") no-repeat left center;
        padding: 0 1.5em 0 2.2em;
        font-size: rem(15px);
        min-height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        @include SP {
          font-size: rem(13px);
        }
        &:after {
          content: "";
          background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.533%22%20height%3D%228.266%22%20viewBox%3D%220%200%2015.533%208.266%22%3E%0A%20%20%3Cpath%20id%3D%22chevron_down%22%20d%3D%22M14.618.5%2C7.559%2C7.559.5.5%22%20transform%3D%22translate(0.207%200.207)%22%20fill%3D%22none%22%20stroke%3D%22%233e2f2f%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221%22%2F%3E%0A%3C%2Fsvg%3E%0A') no-repeat center;
          background-size: contain;
          width: 14px;
          height: 7px;
          position: absolute;
          right: 0;
        }
      }
      .answer {
        font-size: rem(14px);
        border-top: 1px solid #C7C5C5;
        padding: 15px 0;
        line-height: 1.8;
        margin-top: 6px;
        @include SP {
          font-size: rem(12px);
        }
        a {
          font-weight: bold;
          text-decoration: none;
          border-bottom: 1px solid;
        }
      }
    }
  }
}

/* ==================================================================
 *  legal
 * ================================================================== */

.legal {
  width: calc(100% - 50px);
  max-width: 850px;
  margin: 0 auto;

  h2 {
    margin: 100px 0 50px;
    font-size: rem(21px);
    color: #3E2F2F;
    line-height: 1.4;
    font-weight: bold;
  }

  .read {
    font-size: 90%;
    line-height: 1.6;
    margin-bottom: 3em;
  }

  a {
    text-decoration: underline;
    font-size: 85%;
  }

  ul {
    >li {
      margin-bottom: 2em;
      h3 {
        font-weight: bold;
        margin-bottom: 10px;
        border-bottom: 1px solid #C7C5C5;
        padding-bottom: 5px;
        font-size: rem(14px);
      }
      p {
        margin-bottom: 1em;
        font-size: 90%;
        line-height: 1.5;
      }
      ol {
        padding-bottom: 0.5em;
        line-height: 1.7;
        li{
          list-style: decimal;
          list-style-position: inside;
          padding-left: 1.2em;
          text-indent: -1em;
          font-size: 85%;
          letter-spacing: 0.1em;
          font-feature-settings: "palt";
        }
      }
    }
  }
}


/* ==================================================================
 *  footer
 * ================================================================== */

footer {
  width: 100%;
  text-align: center;
  z-index: 2;
  .foot_link {
    font-size: rem(13px);
    margin: 100px auto;
    li {
      margin-bottom: 1.5em;
    }
  }
  .copy {
    font-size: 75%;
    padding: 30px 0;
  }
}

/* ==================================================================
 *  error
 * ================================================================== */

.invalid-code {
  margin-top: 50px;
  padding: 15px;
}
